import { produce } from "immer";
import UplaodIState from "models/UploadDocuments";
import { uploadDocuments } from "actions";

const initialState: UplaodIState = {
    uploadedDocuments: [],
    uploadedDocumentsPerPerson: [],
    uploadedDocumentsLocally: [],
    voiceRecorder: "",
    captureScreen: "",
    captureType: "",
    currentModal: "",
    attachmentTypes: [],
    action: {
        isLoading: false,
        error: null,
        isSuccess: false,
        message: "",
        actionName: null
    }
};

const uploadDocumentsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case uploadDocuments.SET_CAPTURE_SCREEN:
                draft.captureScreen = action.payload;
                break;
            case uploadDocuments.SET_CAPTURE_TYPE:
                draft.captureType = action.payload;
                break;
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_PER_PERSON_BEGIN:
            case uploadDocuments.REMOVE_FROM_FAVORIT_DOCUMENT_BEGIN:
            case uploadDocuments.ADD_TO_FAVORIT_DOCUMENT_BEGIN:
            case uploadDocuments.POST_UPLOAD_DOCUMENT_BEGIN:
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case uploadDocuments.REMOVE_FROM_FAVORIT_DOCUMENT_SUCCESS:
            case uploadDocuments.POST_UPLOAD_DOCUMENT_SUCCESS:
            case uploadDocuments.ADD_TO_FAVORIT_DOCUMENT_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = action.type;
                draft.action.message = "";
                draft.action.error = null;
                break;
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_PER_PERSON_FAILED:
            case uploadDocuments.REMOVE_FROM_FAVORIT_DOCUMENT_FAILED:
            case uploadDocuments.ADD_TO_FAVORIT_DOCUMENT_FAILED:
            case uploadDocuments.POST_UPLOAD_DOCUMENT_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_SUCCESS:
                draft.uploadedDocuments = action.payload.attachments || [];
                draft.action.isSuccess = true;
                draft.action.actionName = uploadDocuments.GET_UPLOAD_DOCUMENTS_SUCCESS;
                draft.action.isLoading = false;
                draft.uploadedDocumentsLocally = [];
                break;
            case uploadDocuments.GET_UPLOAD_DOCUMENTS_PER_PERSON_SUCCESS:
                draft.uploadedDocumentsPerPerson = action.payload.attachments || [];
                draft.action.isSuccess = true;
                draft.action.actionName = uploadDocuments.GET_UPLOAD_DOCUMENTS_SUCCESS;
                draft.action.isLoading = false;
                draft.uploadedDocumentsLocally = [];
                break;
            case uploadDocuments.GET_ATTACHMENT_TYPES_SUCCESS:
                draft.attachmentTypes = action.payload;
                draft.action.isSuccess = true;
                draft.action.actionName = uploadDocuments.GET_ATTACHMENT_TYPES_SUCCESS;
                // draft.action.isLoading = false;
                break;
            case uploadDocuments.SET_CURRENT_MODAL:
                draft.currentModal = action.payload;
                break;
            case uploadDocuments.SET_UPLOADED_DOCUMENTS_MESSAGE:
                draft.action.message = action.payload;
                break;
            case uploadDocuments.SET_UPLOAD_DOCUMENTS_LOCALLY:
                draft.uploadedDocumentsLocally = [...draft.uploadedDocumentsLocally, action.payload];
                break;
            case uploadDocuments.DELETE_UPLOAD_DOCUMENT_LOCALLY:
                const indexToDelete = action.payload;
                draft.uploadedDocumentsLocally.splice(indexToDelete, 1);
                break;
            case uploadDocuments.CLEAR_UPLOAD_DOCS_ACTION:
                draft.action.message = "";
                break;
            default:
                break;
        }
    });
export default uploadDocumentsReducer;
