import properties from "properties";

export const EcocareLogoWhite = require("../../styles/assets/ecocare_logo_white.webp");
export const EcocareLogoNew = require("../../styles/assets/new_ecocare_logo.webp");
export const ProductExample = require("../../styles/assets/product.webp");
export const Phone = require("../../styles/assets/icons/phone-alt-light.png");
export const Hours = require("../../styles/assets/icons/clock-light.png");
export const Envelope = require("../../styles/assets/icons/envelope-open-light.png");
export const ShoppingCart = require("../../styles/assets/icons/shopping-cart.png");
export const CloseButton = require("../../styles/assets/icons/close-button.png");
export const TurmApotheke = require("../../styles/assets/icons/TurmApothekeTest.png");
export const SecureBadge = require("../../styles/assets/secure-badge.png");
export const heartImage = require("../../styles/assets/heart-auscultation.png");
export const lungImage = require("../../styles/assets/lung-auscultation.png");

export const images = {
    image1: `${properties.AWS_BUCKET}images/01.webp`,
    image2: `${properties.AWS_BUCKET}images/02.webp`,
    image3: `${properties.AWS_BUCKET}images/03.webp`,
    image4: `${properties.AWS_BUCKET}images/04.webp`,
    image5: `${properties.AWS_BUCKET}images/05.webp`,
    image6: `${properties.AWS_BUCKET}images/06.webp`,
    image7: `${properties.AWS_BUCKET}images/07.webp`,
    image8: `${properties.AWS_BUCKET}images/08.webp`,
    image9: `${properties.AWS_BUCKET}images/09.webp`,
    image10: `${properties.AWS_BUCKET}images/10.webp`,
    image11: `${properties.AWS_BUCKET}images/11.webp`,
    image12: `${properties.AWS_BUCKET}images/12.webp`,
    imagePfeil12: `${properties.AWS_BUCKET}images/12 Pfeil.webp`,
    image13: `${properties.AWS_BUCKET}images/13.webp`,
    image14: `${properties.AWS_BUCKET}images/14.webp`,
    imagePfeil14: `${properties.AWS_BUCKET}images/14 Pfeil.webp`,
    image15: `${properties.AWS_BUCKET}images/15.webp`,
    image16: `${properties.AWS_BUCKET}images/16.webp`,
    image17: `${properties.AWS_BUCKET}images/17.webp`,
    image18: `${properties.AWS_BUCKET}images/18.webp`,
    image19: `${properties.AWS_BUCKET}images/19.webp`,
    image20: `${properties.AWS_BUCKET}images/20.webp`,
    image21: `${properties.AWS_BUCKET}images/21.webp`,
    image22: `${properties.AWS_BUCKET}images/22.webp`
};
